import { ArrowLeftIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Routes, Route, Link } from "react-router-dom";
import { Account } from "./Account";
import { Dashboard } from "./Dashboard";
import { Files } from "./Files";
import { Upload } from "./Upload";
import {Amplify, Auth} from 'aws-amplify';

import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import { theme } from "../styles/Authenticator-Theme";
import { UseNavToggleContext } from "../hooks/useNavToggleContext";
import { useContext } from "react";
Amplify.configure(awsExports);    

export const Home = () => {
    const nav = useContext(UseNavToggleContext);
    const services = {
    async handleSignUp(formData: any) {
      let { username, password, attributes } = formData;
      try {
          const user_object = await Auth.signUp({
        username,
        password,
        attributes
      });
          return user_object
      }
      catch (error) {
        alert("This user is not allowed to sign up as of now.")

      }

    },
  };
    return (
    <>    
    <ThemeProvider theme={theme}>
    <Authenticator services={services}>
        {({ signOut, user }) => (
            <div className={classNames({"flex h-screen transition": true})}>
                <div className={classNames({"bg-primary relative z-50": true, 'w-4': !nav.isExpanded  })}>
                <ArrowLeftIcon 
                            onClick={() => nav.setIsExpanded(!nav.isExpanded)}
                            className={classNames({
                                "cursor-pointer hover:scale-125 transition w-8 h-8 bg-primary rounded-full text-white absolute top-8 -right-4": true, 
                                "rotate-180": !nav.isExpanded
                        })} />
                    <div className={classNames({"hidden": !nav.isExpanded})}>
                        <Link className="flex flex-col text-3xl text-left px-10 py-8 text-white font-bold uppercase" to="/upload">
                            Benetech
                        </Link>
                        <nav className="flex flex-col text-left w-60 h-[84vh]">
                            {/* <Link className="cursor-pointer transition hover:bg-secondary px-10 py-4 text-white" to="dashboard">Dashboard</Link> */}
                            <Link className="cursor-pointer transition hover:bg-secondary px-10 py-4 text-white" to="upload">Upload</Link>
                            <Link className="cursor-pointer transition hover:bg-secondary px-10 py-4 text-white" to="files">Files</Link>
                            <span className="flex flex-col justify-end h-full">
                                <Link className="cursor-pointer transition hover:bg-secondary px-10 py-4 text-white" to="account">Account</Link>
                                <span className="cursor-pointer transition hover:bg-secondary px-10 py-4 text-white" onClick={signOut}>Sign out</span>
                            </span>
                        </nav>
                    </div>
                </div>
                <div className="w-full px-10">
                <Routes>
                    {/* <Route path="dashboard" element={<Dashboard />} /> */}
                    <Route path="/" element={<Upload user={user?.username} />} />
                    <Route path="files/*" element={<Files user={user?.username} />} />
                    <Route path="upload" element={<Upload user={user?.username} />} />
                    <Route path="account" element={<Account user={user?.username} />} />
                </Routes>
                </div>
            </div>
            )
        }
        </Authenticator>
        </ThemeProvider>
    </>
    )
}