import { createContext, useEffect, useState } from "react";

const UsePageContext = createContext<any>(null);
const UsePageProvider = ({ children }: any) => {
  const [page, setPage] = useState<any>();

  useEffect(() =>{
    if (!page) {
      if (sessionStorage.page) {
        setPage(JSON.parse(sessionStorage.page));
      } else {
        setPage(1)
      }
    } else {
      window.sessionStorage.setItem('page', JSON.stringify(page));
    }
  }, [page])

  return (
    <UsePageContext.Provider
      value={{
        page,
        setPage
      }}
    >
      {children}
    </UsePageContext.Provider>
  );
};

export { UsePageProvider, UsePageContext };