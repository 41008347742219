import {Theme } from '@aws-amplify/ui-react';

export const theme: Theme = {
    name: 'Auth Example Theme',
    tokens: { 
      colors: {
        background: {
          primary: {
            value: '#fff',
          },
          secondary: {
            value: '#EFA300',
          },
        },
        font: {
          primary: {
              value: '#b74900'
          },
        },
        border: {
          primary: {
              value: '#b74900'
          }
        }
      },
      components: {
          tabs: {
              item: {
                  color: {
                      value: '#fff'
                  },
                  borderColor: {
                      value: '#EFA300'
                  },
                  _hover: {
                      color: {
                          value: '#b74900'
                      }
                  },
                  _active: {
                      color: {
                          value: '#b74900'
                      },
                      borderColor: {
                          value: '#b74900'
                      }
                  },
                  _focus: {
                      color: {
                          value: '#b74900'
                      },
                  },
              },
              backgroundColor: {
                  value: '#fff'
              },
             
          },
          button: {
              borderColor: {
                  value: '#b74900'
              },
              color: {
                  value: '#b74900'
              },
              primary: {
                  backgroundColor: {
                      value: '#b74900'
                  },
                  _hover: {
                      backgroundColor: {
                          value: '#EFA300'
                      },
                  }
              }
          },
          fieldcontrol: {
              borderColor: {
                  value: '#b74900'
              },
              _focus: {
                  borderColor: {
                      value: '#b74900'
                  },
                  boxShadow: {
                      value: '0px 1px 3px #b74900'
                  }
              },
              color: {
                  value: '#000'
              }
          }
      }
      }
  }