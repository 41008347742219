import { Home } from './pages/Home';
import { BrowserRouter } from "react-router-dom";
import { UseBookProvider } from './hooks/useBookContext';
import { UsePageProvider } from './hooks/usePageContext';
import { UseNavToggleProvider } from './hooks/useNavToggleContext';


function App() {
  return (
    <BrowserRouter>
      <UseBookProvider>
        <UsePageProvider>
          <UseNavToggleProvider>
              <Home  />
          </UseNavToggleProvider>
        </UsePageProvider>
      </UseBookProvider>
    </BrowserRouter>
  );
}

export default App;
