import { ChevronRightIcon, SearchIcon, SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/solid"
import { Auth } from "aws-amplify";
import axios from "axios";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Book } from "../components/Book";
import { LoadingIcon } from "../components/LoadingIcon";
import { PageContainer } from "../components/PageContainer";
import { PagesOverlay } from "../components/PagesOverlay";
import { UseBookContext } from "../hooks/useBookContext";
import { UsePageContext } from "../hooks/usePageContext";
export interface Books {
    items: Book[]
}

export interface Page {
    number: number;
}

export const Files = (username: any) => {
    const book = useContext(UseBookContext);
    const page = useContext(UsePageContext);
    const location = useLocation();
    const [isBook, setIsBook] = useState(false);
    const [pagesState, setPagesState] = useState([]);
    const convertedBookTile = book?.book?.title.replace(/\s+/g, '-');
    let madePages: any = []

    useEffect(() => {
        book?.book ? setIsBook(true) : setIsBook(false)
    }, [book])

    useEffect(() => {
        madePages = []
        for (let i = 1; i <= book?.book?.pages; i++) {
            madePages.push(i)
        }
        setPagesState(madePages)
    }, [book?.book?.pages])

    return (
        <>
            <div className="flex space-x-4 items-center">
                <Link className="text-3xl text-left my-8 font-bold underline hover:text-primary transition" to="/files">
                    Files
                </Link>
                {location.pathname.includes(convertedBookTile) && 
                <>
                    <ChevronRightIcon className="w-8" />
                    <h1 className="text-3xl text-left my-8 font-bold">
                        {book.book.title}
                    </h1>
                </>
                }
            </div>
            <Routes>
                <Route path="" element={<FilesContainer username={username.user} />}></Route>
                <Route path={`${convertedBookTile}/${page.page}`} element={<PageContainer username={username.user} />} />
            </Routes>
            {
                isBook ? (
                    <PagesOverlay pages={pagesState} />
                ) : null
            } 
        </>
    )
}
const pai_env = process.env.REACT_APP_PAI_ENV == undefined ? "dev" : process.env.REACT_APP_PAI_ENV;
var api = "";
var url = window.location.href;
if (url.includes("localhost") || url.includes("amplifyapp") || url.includes("dev")) {   
    api = `api.${pai_env}.pageai`;
} else if (window.location.href.includes(".pageai")){
    api = "api.pageai";
}
const FilesContainer = ({username}: any) => {
    const [ searchInput, setSearchInput ] = useState("");
    const [ sort, setSort ] = useState("asc");
    const [ listOfBooks, setListOfBooks ] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const getBooks = async () => {
        setLoading(true);
        await username;

        const url = `https://${api}.org/v1/title_instances/fetch_title_instances`;
        axios.get(url, {
            headers: {
                "Content-Type": "image/png",
                "X-PageAI-ApiKey": "fe_198282gfbw6fadb9823e7gbd98e7ga72e8e98dfh8awer627g1",
                "X-PageAI-User": username,
              },
        }).then(res => {
            setLoading(false);
            setListOfBooks(res.data.title_instances);
        })
    }

    useEffect(() => {
        getBooks()
    }, [username])

    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    if (searchInput.length > 0) {
        listOfBooks.filter((option: any) => {
            option.title_name.toLowerCase().includes(searchInput);
        });
    }

    return (
        <>
            <div className="flex justify-between items-end">
                <div className="relative h-10">
                    <input 
                        onChange={(e) => handleChange(e)}
                        value={searchInput}
                        placeholder="Search..." 
                        className="outline-none focus:border-2 focus:opacity-100 opacity-70 transition px-2 border border-primary rounded w-80 py-2" />
                    <SearchIcon className="absolute right-3 top-2 w-6 h-6 text-primary"/>
                </div>
                <div className="flex space-x-6">
                    <div 
                        onClick={() => setSort(sort === "asc" ? "desc" : "asc")}
                        className="flex items-center space-x-1 cursor-pointer">
                        {sort === "asc" && <SortAscendingIcon className="w-6 h-6 text-primary" />}
                        {sort === "desc" && <SortDescendingIcon className="w-6 h-6 text-primary" />}
                        <span>Sort</span>
                    </div>
                </div>
            </div>
            <hr className="my-4 text-primary" />
            <ul className="grid xl:grid-cols-4 grid-cols-2 gap-12 overflow-y-scroll h-[72vh]">
            {
                loading ? (
                    <div className="flex justify-center items-center col-span-4">
                        <LoadingIcon />
                    </div>
                ) : (
                    listOfBooks.filter((book: any) => {
                        if (searchInput === '') {
                          return book;
                        } else if (book.title_name.toLowerCase().includes(searchInput.toLowerCase())) {
                          return book;
                        }}).sort((a: any, b: any) => {
                          if (sort === "asc") {return a.title_name?.localeCompare(b.title_name)}
                          else if (sort === "desc") {return b.title_name?.localeCompare(a.title_name)}
                          return 0
                        }).map((book: any, index: number) => (
                              <li key={index} className={classNames({
                                "w-68 cursor-pointer hover:scale-110 transition h-60 hover:first:translate-x-4": true, 
                                "hover:-translate-x-4": index % 3 === 0, 
                                "hover:translate-x-4": index % 4 === 0})}>
                                    {/* uncomment below when confidence, status, lastupdated and chapters are ready */}
                                      <Book 
                                          title={book.title_name} 
                                          titleInstanceId={book.title_instance_id}
                                          pages={book.page_count}
                                          // confidence={book.confidence} 
                                          // status={book.status} 
                                          // lastUpdated={book.lastUpdated}
                                          // chapters={book.chapters}
                                      />      
                              </li>
                          )))}
            </ul>
        </>
    )
}