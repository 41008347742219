import FileUploader from "../components/FileUploader"

export const Upload = (username: any) => {
    return (
        <>
            <h1 className="text-3xl text-left my-8 font-bold">
                Upload
            </h1>
            <FileUploader username={username} />
        </>
    )
}