import { InformationCircleIcon, SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/solid"
import { Page } from "../pages/Files"
import { useContext, useState } from "react"
import { UseBookContext } from "../hooks/useBookContext"
import { useNavigate, useLocation } from "react-router-dom"
import { UsePageContext } from "../hooks/usePageContext"
import classNames from "classnames"
import { UseNavToggleContext } from "../hooks/useNavToggleContext"

export interface Pages {
    pages: Page[]
}

export const PagesOverlay = ({pages}: any) => {
    const book = useContext(UseBookContext);
    const pageFromContext = useContext(UsePageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const nav = useContext(UseNavToggleContext);
    const convertedBookTile = book?.book?.title.replace(/\s+/g, '-');
    const [ sort, setSort ] = useState("asc");
    // const [ localPage, setLocalPage ] = useState(pageFromContext.page);
    let route = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
    const handleChange = (item: any) => {
        console.log(item)
        pageFromContext.setPage(item)
        navigate(`${convertedBookTile}/${item}`)
    }

    // For session resume
    // useEffect(() => {
    //     if (localPage) {
    //         pageFromContext.setPage(localPage)
    //     } else {
    //         setLocalPage(pageFromContext.page)
    //     }
    // }, [localPage])

    return (
        <div className="bg-secondary z-20 flex absolute bottom-0 left-0 w-full overflow-hidden max-h-12 hover:max-h-80 transition-all">
            <div className={classNames({"w-full px-8 py-2 space-y-4 transition": true, 'ml-64': nav.isExpanded, 'ml-10': !nav.isExpanded})}>
                <div className="flex justify-between items-center w-full px-2">
                    <div className="flex space-x-2 items-center">
                        <h1 className="text-xl text-left font-bold text-white">
                            {book.book.title}
                        </h1>
                        <InformationCircleIcon className="w-4 h-4 text-white cursor-pointer"/>
                        <select defaultValue={0} className="outline-none focus:border-2 focus:opacity-100 opacity-70 transition px-2 border border-white rounded w-60 py-1">
                            <option>Table of Content</option>
                            {/* Implemented when chapters data is supported on backend */}
                            {/* {
                                book?.book?.chapters?.map((chapter: any, index: any) => (
                                    <option key={index}>{chapter}</option>
                                ))
                            } */}
                        </select>
                    </div>
                    <div className="relative h-10 space-x-1">
                        <input 
                            onChange={(e) => handleChange(e.target.value)}
                            value={pageFromContext.page}
                            className="outline-none text-center focus:border-2 focus:opacity-100 opacity-70 transition px-2 border border-white rounded w-12 py-1" />
                        <span className="text-white">/ {pages.length}</span>
                    </div>
                    <div className="flex space-x-6">
                        <div 
                            className="flex items-center space-x-1 cursor-pointer"
                            onClick={() => setSort(sort === "asc" ? "desc" : "asc")}>
                            {sort === "asc" && <SortAscendingIcon className="w-6 h-6 text-white" />}
                            {sort === "desc" && <SortDescendingIcon className="w-6 h-6 text-white" />}
                            <span className="text-white">Sort</span>
                        </div>
                    </div>
                </div>
                <ul className="flex flex-wrap h-60 overflow-y-auto px-2"> 
                    {pages.sort((a: any, b: any) => {
                        if (sort === "asc") {return a - b}
                        else if (sort === "desc") {return b - a}
                        return 0
                        }).map((page: any, index: any) => (
                            <li 
                                className="flex flex-col items-center mr-4 mt-4 hover:scale-110 transition cursor-pointer"
                                key={index}
                                onClick={() => handleChange(page)}
                            >
                                <span className={classNames({"w-40 h-48 bg-white": true, "border-2 border-primary": page === Number(route)})}></span>
                                <span className="text-white">{page}</span>
                            </li> 
                            )
                        )
                    }
                 </ul>
            </div>
        </div>
    )
}