import { useEffect, useState } from "react";
import { useUploadForm } from "../hooks/useUploadForm";
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { CheckboxField } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';
const pai_env = process.env.REACT_APP_PAI_ENV == undefined ? "dev" : process.env.REACT_APP_PAI_ENV;

var api = "";
var url = window.location.href;
if (url.includes("localhost") || url.includes("amplifyapp") || url.includes("dev")) {   
    api = `api.${pai_env}.pageai`;
} else if (window.location.href.includes(".pageai")){
    api = "api.pageai";
}

interface PutData {
    mathML: boolean;
    altText: boolean;
    file: File | null;
    title: string;
}

const FileUploader = ({username}: any) => {
    const [mathMlConfidence, setMathMlConfidence] = useState('');
    const [altTextConfidence, setAltTextConfidence] = useState('');
    const [title, setTitle] = useState<string>('');
    const [titleError, setTitleError] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');

    const { isSuccess, uploadForm, progress } = useUploadForm(
        `https://${api}.org/v1/uploads`,
        username.user,
        title
    );


    const [formValues, setFormValues] = useState<PutData>({
        mathML: false,
        altText: false,
        file: null,
        title: '',
    });

    const handleMathMLChange = (event: any) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            mathML: event.target.checked,
        }));
    };

    const handleAltTextChange = (event: any) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            altText: event.target.checked,
        }));
    };

    const handleFileChange = (event: any) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            file: event.target.files ? event.target.files[0] : null
        }))
    }

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            title: event.target.value ? event.target.value : ''
        }))
    }

    const handleSubmit = async () => {
        if (!title) {
            setTitleError('Title is required');
            return;
        } else {
            setTitleError('');
        }

        if (!formValues.file) {
            setFileError('File is required');
            return;
        } else {
            setFileError('');
        }

        const formData = new FormData();
        formValues.file && formData.append("file", formValues.file);
        // TODO: add title, alt and mathml change to formData in the future when the API supports it
        // formValues.title && formData.append("title", formValues.title);
        return await uploadForm(formData);
    }

    return (
        <div className="flex flex-col space-y-2 w-100">
            <span className="font-bold mb-4">Please check at least one of the options below and set a confidence threshold:</span>
            <div className="grid grid-cols-8 border-b border-primary">
                <span className="col-span-1 text-primary font-bold">Select</span>
                <span className="col-span-5 text-primary font-bold">Description</span>
                <span className="col-span-2 text-primary font-bold">Confidence Threshold</span>
            </div>
            <div className="grid grid-cols-8 items-center relative">
                <span className="col-span-1">
                    <CheckboxField label={''} onChange={(e) => handleMathMLChange(e)}  value={"MathML"} name={"MathML"} />
                </span>
                <span className="col-span-5">Run MathML on uploaded file.</span>
                <input className="col-span-2 border border-primary rounded p-1" type="range" onChange={(e) => setMathMlConfidence(e.target.value)} defaultValue={75} name="math-ml-confidence" />
                <span className="absolute right-0 top-5 text-xs opacity-70">{mathMlConfidence}%</span>
            </div>
            <div className="grid grid-cols-8 items-center relative">
                <span className="col-span-1">
                    <CheckboxField label={''} onChange={(e) => handleAltTextChange(e)} value={"altText"} name={"altText"} />
                </span>
                <span className="col-span-5">Add Alt Text Descriptions on uploaded file.</span>
                <input className="col-span-2 border border-primary rounded p-1" type="range" onChange={(e) => setAltTextConfidence(e.target.value)} defaultValue={75} name="alt-text-confidence" />
                <span className="absolute right-0 top-5 text-xs opacity-70">{altTextConfidence}%</span>
            </div>
            <br />
            <br />
            <div className="flex flex-col items-baseline">
                <span >Please enter the <span className="font-bold">title</span> of your book/file:</span>
                <input className="border border-primary rounded px-2 py-1 mb-4 w-full outline-none" placeholder="The Great Gatsby" type="text" onChange={(e) => handleTitleChange(e)} name="title" required />
                {titleError && <div className="flex space-x-1 mb-2 -mt-2 items-center"><ExclamationCircleIcon className="w-6 text-critical" /><span className="text-critical">{titleError}</span></div>}
            </div>
            <label htmlFor="uploader" className="hover:bg-secondary text-center hover:text-white hover:border-secondary transition w-full py-2 px-4 border border-primary text-primary cursor-pointer">{formValues.file?.name ?? "Upload File"}</label>
            <input onChange={(e) => handleFileChange(e)} type="file" hidden id="uploader" />
            {fileError && <div className="flex space-x-1"><ExclamationCircleIcon className="w-6 text-critical" /><span className="text-critical">{fileError}</span></div>}

            {isSuccess ? (
                <div className="flex space-x-1 items-center justify-center">
                    <CheckCircleIcon className="w-8 h-8 text-success"/>
                    <span className="text-success font-bold">Your file is being processed and the EPUB will appear on Files page when the processing is complete.</span>
                </div>
                ) :
                <>
                    <button onClick={handleSubmit} className="py-2 px-4 border bg-primary text-white">Submit</button>
                    <progress max="100" value={progress} className="w-96 rounded m-auto h-4 text-primary bg-primary"></progress>
                </>
            }
        </div>
    )
}

export default FileUploader;