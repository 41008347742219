import { createContext, useEffect, useState } from "react";

const UseBookContext = createContext<any>(null);
const UseBookProvider = ({ children }: any) => {
  const [book, setBook] = useState<any>();

  useEffect(() =>{
    if (!book) {
      if (sessionStorage.book) {
        setBook(JSON.parse(sessionStorage.book));
      } else {
        return;
      }
    } else {
      window.sessionStorage.setItem('book', JSON.stringify(book));
    }
  }, [book])

  return (
    <UseBookContext.Provider
      value={{
        book,
        setBook
      }}
    >
      {children}
    </UseBookContext.Provider>
  );
};

export { UseBookProvider, UseBookContext };