import { createContext, useState } from "react";

const UseNavToggleContext = createContext<any>(null);


const UseNavToggleProvider = ({ children }: any) => {
  const [isExpanded, setIsExpanded] = useState<Boolean>(true);

  return (
    <UseNavToggleContext.Provider
      value={{
        isExpanded,
        setIsExpanded
      }}
    >
      {children}
    </UseNavToggleContext.Provider>
  );
};

export { UseNavToggleProvider, UseNavToggleContext };