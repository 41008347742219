import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import axios from "axios";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UseBookContext } from "../hooks/useBookContext";
import { UsePageContext } from "../hooks/usePageContext";
const pai_env = process.env.REACT_APP_PAI_ENV == undefined ? "dev" : process.env.REACT_APP_PAI_ENV;
var api = "";
var url = window.location.href;
if (url.includes("localhost") || url.includes("amplifyapp") || url.includes("dev")) {   
    api = `api.${pai_env}.pageai`;
} else if (window.location.href.includes(".pageai")){
    api = "api.pageai";
}

export const PageContainer = ({username}: any) => {
    let iframe: any = document.getElementById('frame');
    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImageHeight] = useState(0);
    const [image, setImage] = useState('');
    const [file, setFile] = useState('');
    const [boundingBoxes, setBoundingBoxes] = useState<any>(); 
    const [disableNav, setDisableNav] = useState(true);
    const [editing, setEditing] = useState(false);
    const [fileChanged, setFileChanged] = useState(false);
    const [reconstructionOcurring, setReconstructionOcurring] = useState(false);
    let ratioWidth = 0;
    let ratioHeight = 0;
    const containerImage = document.getElementById('img')! as HTMLImageElement;
    const pageFromContext = useContext(UsePageContext);
    const book = useContext(UseBookContext);
    const convertedBookTile = book?.book?.title.replace(/\s+/g, '-');
    const navigate = useNavigate();
    const location = useLocation();
    // Add to .env when needed for security
    const apikey = 'fe_198282gfbw6fadb9823e7gbd98e7ga72e8e98dfh8awer627g1'
    
    const fetchImage = async (page: number) => {
        const url = `https://${api}.org/v1/title_instances/${book?.book?.titleInstanceId}/${page-1}?format=png`;

        await axios.get(url, {
            headers: {
                "Content-Type": "image/png",
                "X-PageAI-ApiKey": apikey,
                "X-PageAI-User": username,
                },
        }).then(res => {
            setImage(url)
        })
    }

const fetchXHTML = async (page: number) => {
        const url = `https://${api}.org/v1/title_instances/${book?.book?.titleInstanceId}/${page-1}?format=xhtml`;
        await axios.get(url, {
            headers: {
                'Cache-Control': 'no-cache',
                "Content-Type": "xhtml",
                "X-PageAI-ApiKey": apikey,
                "X-PageAI-User": username,
                },
        }).then(res => {
            setFile(res.data)
        })
    }

    const fetchJSON = async (page: number) => {
        const url = `https://${api}.org/v1/title_instances/${book?.book?.titleInstanceId}/${page-1}?format=json`;
        await axios.get(url, {
            headers: {
                "Content-Type": "json",
                "X-PageAI-ApiKey": apikey,
                "X-PageAI-User": username,
            },
        }).then(res => {
            setBoundingBoxes(res.data.bounding_boxes)
        })
    }

    const loadXhtmlImages = () => {
        let images = iframe.contentWindow.document.images;
        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                images[i].src = `https://${api}.org/v1/epub_resources/${book?.book?.titleInstanceId}/` + images[i].getAttribute('src') + `?x-pageai-user=${username}&x-pageai-apikey=${apikey}`
                images[i].style.width = '100%'
            }
        }
    }

    const onImgLoad = async (target: any) => {
        setImgWidth(containerImage.getBoundingClientRect().width);
        setImageHeight(containerImage.getBoundingClientRect().height);
        ratioWidth = await containerImage.getBoundingClientRect().width / target.nativeEvent.srcElement.naturalWidth;
        ratioHeight = await containerImage.getBoundingClientRect().height / target.nativeEvent.srcElement.naturalHeight;
        drawRectangles()
    }

    const drawRectangles = () => {
        let c = document.getElementById("canvas")! as HTMLCanvasElement;
        let ctx = c.getContext("2d");
        ctx?.beginPath();
        setTimeout(() => {
            Object.values(boundingBoxes[0]).forEach((box: any, index: any) => {
                ctx?.rect(
                  box[0][0] * ratioWidth,
                  box[0][1] * ratioHeight,
                  (box[0][2] - box[0][0]) * ratioWidth,
                  (box[0][3] - box[0][1]) * ratioHeight
                );
                if (ctx) {
                  ctx.strokeStyle = box[0][5] === 1 ? '#22AEFD' : '#b74900'
              }
              });
              ctx?.stroke();
        }, 0); 
    }
  
    const clearRect = () => {
        let c = document.getElementById("canvas")! as HTMLCanvasElement;
        let ctx = c.getContext("2d");
        if (boundingBoxes) {
            Object.values(boundingBoxes[0]).forEach((box: any, index: any) => {
                ctx?.clearRect(0,
                    0,
                    10000,
                    10000);
                ctx?.beginPath()
            });
        }
    }

    const prevPage = () => {
        if (pageFromContext.page === 1) {return} else {
            pageFromContext.setPage(pageFromContext.page - 1)
            navigate(`/files/${convertedBookTile}/${pageFromContext.page - 1}`)
        }
    }

    const nextPage = () => {
        if (pageFromContext.page === book.book.pages) {return} else {
            pageFromContext.setPage(pageFromContext.page + 1)
            navigate(`/files/${convertedBookTile}/${pageFromContext.page + 1}`)
        }
    }

    const save = async (segmentId: string, body: string) => {
        setFileChanged(true)
        const url = `https://${api}.org/v1/update_segment/${segmentId}/${book?.book?.titleInstanceId}/${pageFromContext.page - 1}`;
        await axios.post(url, body, {
            headers: {
                "X-PageAI-ApiKey": apikey,
                "X-PageAI-User": username,
              },
        })
    }

    const saveAllEdits = async () => {
        const url = `https://${api}.org/v1/reconstruct_epub/${book?.book?.titleInstanceId}`;
        await axios.get(url, {
            headers: {
                "X-PageAI-ApiKey": apikey,
                "X-PageAI-User": username,
              },
        })
        setReconstructionOcurring(true)
        setTimeout(() => {
            setReconstructionOcurring(false)
        }, 30000)
    }

    // File getting large - Can add below to a utils file
    const edit = () => {
        setEditing(true);
        iframe.contentWindow.document.body.style.fontFamily = 'sans-serif';
        let elements = iframe.contentWindow.document.querySelectorAll('[data-segment-id]');
        if (elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].tagName === 'math' || elements[i].className == 'mjx-math') {
                    elements[i].style.cssText = "color: #b74900; position: relative";
                } else {
                    elements[i].style.cssText = "color: #EFA300; position: relative";
                }
                iframe.contentWindow.document.body.onclick = function getPosition(e: any) {
                    if (e.target.className.includes("mjx")) {
                        return;
                    }
                    if (e.target.getAttribute('data-segment-id') !== "") {
                        let segmentId = e.target.getAttribute('data-segment-id');
                        let el = e.target;
                        let originalContent = el.innerHTML
                        let inputContainer = document.createElement("div");
                        // use below if need to program linebreaks
                        // let linebreak = document.createElement("br");
                        inputContainer.style.cssText = el.tagName === "IMG" ?  "position: relative" : "top: -14px; position: absolute";
                        let closeIcon = document.createElement("div");
                        closeIcon.innerHTML = "X";
                        closeIcon.style.cssText = "position: absolute; left: -8px; border-radius: 50%; color: white; padding: 8px; top: 0; margin-top: -54px; z-index: 20; background-color:#b74900; font-size: 16px; cursor: pointer;";
                        let elemInput = iframe.contentWindow.document.createElement('textarea');
                        elemInput.style.cssText = `outline: none; position: absolute; color: white; padding: 8px 14px; top: 0; margin-top: -40px; width: 92vw; z-index: 10; border: 1px solid #b74900; background-color:#b74900; font-size: 16px;`;
                        // elemInput.setAttribute('autofocus', 'true');
                        if (el.tagName === "IMG") {
                            elemInput.value = el.getAttribute('alt');
                            inputContainer.id = "alt";
                            inputContainer.appendChild(closeIcon);
                            inputContainer.appendChild(elemInput);
                            el.insertAdjacentElement("beforebegin", inputContainer);
                        } else {
                            elemInput.value = el.innerHTML;
                            inputContainer.appendChild(closeIcon);
                            inputContainer.appendChild(elemInput);
                            el.appendChild(inputContainer);
                        }
                        const saveInput = async () => {
                            console.log(originalContent)
                            console.log(elemInput.value)
                            if (elemInput.value === "" || elemInput.value === originalContent) {
                                if (el.tagName === "IMG") {
                                    el.previousSibling.remove();
                                } else {
                                    el.removeChild(inputContainer);
                                }
                                console.log('no change')
                                return;
                            }
                            if (el.tagName === "IMG") {
                                el.setAttribute('alt', elemInput.value);
                                save(segmentId, elemInput.value);
                                el.previousSibling.remove()
                            } else {
                                el.innerHTML = await elemInput.value;
                                await save(segmentId, el.innerHTML);
                                el.removeChild(inputContainer);
                            }
                            elemInput.setAttribute('autofocus', 'false');
                        }
                        inputContainer.onclick = function(inputEvent: any) {
                            inputEvent.stopPropagation();
                            inputContainer.addEventListener("keydown", (e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    if (elemInput.value !== originalContent) {
                                        saveInput()
                                    } else {
                                        if (el.tagName === "IMG") {
                                            el.previousSibling.remove();
                                        } else {
                                            el.removeChild(inputContainer);
                                        }
                                    }
                                } else if (e.key === "Enter" && e.shiftKey) {
                                    // Insert code for line break here if needed
                                }
                            })
                        }
                        closeIcon.onclick = function(closeEvent: any) {
                            closeEvent.stopPropagation();
                            if (elemInput.value !== originalContent) {
                                saveInput()
                            } else {
                                if (el.tagName === "IMG") {
                                    el.previousSibling.remove();
                                } else {
                                    el.removeChild(inputContainer);
                                }
                            }
                        }
                    }
                }

                iframe.contentWindow.document.body.onmouseover = function getPosition(e: any) {
                    let x = e.target.firstChild
                    if (x.className === "mjx-math") {
                        let alt = x.getAttribute('alt');
                        let inputContainer = document.createElement("div");
                        inputContainer.style.cssText = "position: absolute; left: 0; margin-top: -40px; background-color: white";
                        let elemInput = iframe.contentWindow.document.createElement('textarea');
                        elemInput.disabled = true;
                        elemInput.style.cssText = `outline: none; position: absolute; left: 0; color: #b74900; padding: 8px 14px; width: 70vw; z-index: 30; border: 1px solid #b7490zzzz0; background-color:#fff; font-size: 16px`;
                        elemInput.value = alt;
                        inputContainer.appendChild(elemInput);
                        x.appendChild(inputContainer);
                        e.target.addEventListener("mouseleave", function() {
                            x.removeChild(inputContainer);
                        }, false);
                    }
                }
            }                
        }
    }

    useEffect(() => {
        if (location.pathname !== '/files'){
            window.onresize = () => {
                window.location.reload()
         }
        }
    }, [])

    useEffect(() => {
        setEditing(false);
        setDisableNav(true)
        clearRect();
        if (pageFromContext?.page) {
            fetchImage(pageFromContext?.page);
            fetchJSON(pageFromContext?.page);
            fetchXHTML(pageFromContext?.page);
        }
        setTimeout(() => {
            setDisableNav(false)
        }, 300)
    }, [location])
       
    return (
        <>
            <div className="grid grid-cols-2 items-center gap-4">
                <div className="space-y-1 relative">
                    <div className="flex justify-between items-end">
                        <div className="font-bold text-lg">PDF</div>
                        {/* UI elements for when the classification between text and math are request */}
                        {/* <div className="flex-cols space-y-1 -mt-0.5">
                            <div className="flex space-x-2">
                                <span className="w-12 h-4 border-2 border-primary"></span>
                                <div className="text-xs">Text</div>
                            </div>
                            <div className="flex space-x-2">
                                <span className="w-12 h-4 border-2 border-secondary"></span>
                                <div className="text-xs">Math</div>
                            </div>
                        </div> */}
                        {/* Implement when confidence level on backend is ready */}
                        {/* <div className="font-bold text-lg">Confidence Level: 80%</div> */}
                    </div>
                    <div className="border-2">
                        <canvas id="canvas" className="absolute z-20" width={imgWidth} height={imgHeight}></canvas>
                        <img className="w-full h-[78vh]" id="img" src={image} onLoad={onImgLoad} alt="png"/>
                    </div>
                </div>
                <div className="space-y-1">
                    {reconstructionOcurring && <p className="absolute top-0 right-0"><i>Reconstruction in progress. A new download will be ready shortly.</i> </p>}
                    <div className="flex justify-between items-end">
                        <span className="font-bold text-lg">EPUB</span>
                        <div className="flex space-x-2">
                            <button onClick={edit} className={classNames({"py-1 px-8 border bg-primary rounded text-white font-bold cursor-pointer": true, "bg-grey cursor-default": editing})}>{ editing ? 'Editing' : 'Edit' }</button>
                            <button onClick={saveAllEdits} className={classNames({"py-1 px-8 border bg-primary rounded text-white font-bold": true, "bg-grey cursor-default": !fileChanged})}>Reprocess EPUB</button>
                        </div>
                    </div>
                    <div className="p-2 border-2 w-full h-[78vh] overflow-hidden">
                        <iframe loading="lazy" srcDoc={file} id="frame" className="w-full h-[80vh]" title="iframe" onLoad={loadXhtmlImages} />
                        <ArrowLeftIcon 
                            onClick={prevPage}
                            className={classNames({
                                "cursor-pointer hover:scale-125 transition w-8 h-8 bg-secondary rounded-full text-white absolute top-96 mt-20 -translate-x-6": true,
                                "pointer-events-none opacity-50": disableNav
                            })}/>
                        <ArrowRightIcon 
                            onClick={nextPage}
                            className={classNames({
                                "cursor-pointer hover:scale-125 transition w-8 h-8 bg-secondary rounded-full text-white absolute top-96 mt-20 right-12 translate-x-6": true,
                                "pointer-events-none opacity-50": disableNav
                            })}/>
                    </div>
                </div>
            </div>
        </>
    )
}