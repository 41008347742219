import { useState } from "react";
import axios from "axios";

export const useUploadForm = (url: string, username: string, title: string) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [progress, setProgress] = useState(0);

    const uploadForm = async (formData: FormData) => {
        console.log(title)
        await axios.put(url, formData, {
            headers: {
                'X-PageAI-ApiKey': 'fe_198282gfbw6fadb9823e7gbd98e7ga72e8e98dfh8awer627g1',
                'X-PageAI-User': username,
                'title-name': title
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 50;
                setProgress(progress);
            },
            onDownloadProgress: (progressEvent) => {
                const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                setProgress(progress);
            },
        });
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve("success")
                setTimeout(() => {
                    window.location.reload()
                }, 10000);
            }, 500);
        });
        setIsSuccess(true)
        setProgress(0);
    };

    return { uploadForm, isSuccess, progress};
};