export const LoadingIcon = () => {
    return (
        <div className="flex justify-center items-center">
            <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className=" text-primary" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className=" text-secondary" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
            </svg>
            <h1 className="text-2xl text-primary">Loading...</h1>
        </div>
    )
}