import * as Tooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import { useRef, useContext, useEffect, useState } from "react";
import { UseBookContext } from "../hooks/useBookContext";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DownloadIcon, MenuAlt1Icon, MenuAlt3Icon, MenuIcon } from "@heroicons/react/solid";
import { UsePageContext } from "../hooks/usePageContext";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";

// Some of the keys in the interface have been commented out because they are not ready on the backend yet.
export interface Book {
    titleInstanceId: string;
    title: string;
    // confidence: number;
    // status: string;
    // lastUpdated: string;
    // chapters: string[];
    pages: string;
}
const pai_env = process.env.REACT_APP_PAI_ENV == undefined ? "dev" : process.env.REACT_APP_PAI_ENV;
var api = "";
var url = window.location.href;
if (url.includes("localhost") || url.includes("amplifyapp") || url.includes("dev")) {   
    api = `api.${pai_env}.pageai`;
} else if (window.location.href.includes(".pageai")){
    api = "api.pageai";
}


export const Book = ({titleInstanceId, title, pages}: Book) => {
    const FolderContainer: any = useRef();
    const book = useContext(UseBookContext);
    const pageFromContext = useContext(UsePageContext);
    const navigate = useNavigate();
    const convertedBookTile = title.replace(/\s+/g, '-');
    const [user, setUser] = useState<any>();

    const handleClick = async () => {
        await book.setBook({
            titleInstanceId: titleInstanceId,
            title: title, 
            // confidence: confidence, 
            // status: status, 
            // lastUpdated: lastUpdated, 
            // chapters: chapters,
            pages: pages
        })
        // For session resume - this should be uncommented once local storage of progress is requested again.
        // if (pageFromContext?.page) {
        //     navigate(`/files/${convertedBookTile}/${pageFromContext.page}`)
        // }
        pageFromContext.setPage(1)
        navigate(`/files/${convertedBookTile}/1`)
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser({
               bypassCache: false
           }).then(user => {
               setUser(user);
           })
           .catch(err => console.log(err));
       }, [])

    
    const download = async (e: any) => {
        e.stopPropagation();
        await user?.username;
        const url = `https://${api}.org/v1/download_instance/${titleInstanceId}`;
        axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                "X-PageAI-ApiKey": "fe_198282gfbw6fadb9823e7gbd98e7ga72e8e98dfh8awer627g1",
                "X-PageAI-User": user.username,
              },
              responseType: 'blob'
        }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', title)
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    }

    // Status text to be incorporated once the status of the book is requested as a feature and the backend supports it.
    // const renderText = (state: string) => {
    //     switch (state) {
    //         case "Completed":
    //             return "The model is fully confident in its prediction. No need to update.";
    //         case "Warning":
    //             return "The model is somewhat confident in its conversion of the source material. Please double check the results.";
    //         case "Critical":
    //             return "The model is not confident in its conversion of the source material. Please update it.";
    //         default:
    //             return "";
    //     }
    // }

    return (
        <div ref={FolderContainer} onClick={handleClick}>
            <div className="relative">
                <div className={classNames({
                    "w-full h-48 bg-primary ": true, 
                    "bg-secondary": book?.book?.title === title})}>
                </div>  
                <div className="absolute top-2 mt-36 right-0 mr-2 bg-white rounded-full p-1 flex items-center">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger className="outline-none" ><MenuIcon className="w-6 text-secondary" /></DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.Content className="mt-2 w-full">
                                <DropdownMenu.Item onClick={e => download(e)} className="-ml-28 outline-none cursor-pointer flex p-3 justify-between w-32 transition hover:bg-grey hover:text-white bg-white shadow-md">
                                    <span>Download</span>
                                    <DownloadIcon className="w-4" />
                                </DropdownMenu.Item>
                                <DropdownMenu.Separator />
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                </div>
            </div>
            
            <div className="grid grid-cols-5 py-2">
                <div className="text-xl font-bold col-span-5">{title}</div>
                {/* UI for status display and hovers */}
                {/* <div className="flex flex-col space-between text-right col-span-2">
                <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <div className="flex items-center justify-end space-x-1">
                                <span className={classNames({
                                    "text-xs": true,
                                    "text-secondary": status === "Warning",
                                    "text-success": status === "Completed",
                                    "text-critical": status === "Critical",
                                })}>
                                    {status}
                                </span>
                                <span className={classNames({
                                    "rounded-full w-4 h-4": true,
                                    "bg-secondary": status === "Warning",
                                    "bg-success": status === "Completed",
                                    "bg-critical": status === "Critical",
                                })}/>
                            </div>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content sideOffset={0} align="end" className="z-10 bg-black p-1.5 text-white rounded shadow-lg">
                                <Tooltip.Arrow />
                                {renderText(status)}
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                    // UI for last updated display when ready on the backend
                    <span>last updated: {lastUpdated}</span>
                </div> */}
            </div>  
        </div>
    )
}
